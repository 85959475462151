import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../data/images';

class HighTech extends Component {
    render() {
        return (
            <div className='high-container' >

                <div className='high-image-container' >
                    <div className="col-md-8 " >
                        <div className="themesflat-headings style-1 clearfix"> {/*Versão 161 apenas*/}
                            <h1 className='heading'>ALTA TECNOLOGIA</h1>
                            <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />                                               
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <h4>Criamos aplicativos complexos envolvendo tecnologias atuais, como NFT, Blockchain, Big Data, Machine Learning, E-Commerce, BI, CRM e ERP em plataformas Mobile, Desktop e Web.</h4>
                        </div>
                        <div className="widget widget_tags margin-top-55">
                                        <h2 className="widget-title"><span>Somos especialistas em desenvolvimento de soluções tecnológicas.</span></h2>
                                        <div className='high-image-logos-container'>
                                            <img className='high-image-logos' src={images.androidWhite}/>
                                            <img className='high-image-logos' src={images.istqb}/>
                                            <img className='high-image-logos' src={images.appleWhite} style={{height: 140}}/>
                                        </div>
                                    </div>
                    </div>


                    <div className="high-gif-container" >
                        <img className='high-image' src='assets/img/landing/createApp/cel.gif'/>  
                    </div>
                </div>
                
                


            </div>

            
        );
    }
}

export default HighTech;