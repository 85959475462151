import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Completing projects on time & Following budget guidelines'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Elevated quality of workmanship'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Meeting diverse supplier requirements'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Implementing appropriate safety precautions and procedures'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row-about client-background" style={{backgroundColor: '#090e1c'}}>
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-6 half-background style-1"/>
                        <div className="col-md-6 ">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">TRANSFORME SUA IDEIA EM UM NEGÓCIO RENTÁVEL<br/>COM A ORNI</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> 
                                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />                                              
                                    <h4>Criamos <strong>estratégias de negócio</strong> para que seu projeto de software <strong>rentabilize e escale no mercado</strong>, conquistando pay-back rapidamente e aprimorando o <strong>retorno sobre seu investimento.</strong> Além disso, nosso ecossistema aproximará você de investidores parceiros, para aportar recursos financeiros ao seu projeto, se necessário.</h4>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                    <div className="widget widget_tags margin-top-55">
                                        <h2 className="widget-title"><span>Somos especialistas em desenvolvimento de soluções tecnológicas.</span></h2>
                                        <div className="tags-list">
                                            <Link to="#"><strong>Software</strong></Link>
                                            <Link to="#"><strong>App</strong></Link>
                                            <Link to="#"><strong>VR/AR</strong></Link>
                                            <Link to="#"><strong>Marketing</strong></Link>
                                            <Link to="#"><strong>Negócios</strong></Link>
                                            <Link to="#"><strong>UX/UI</strong></Link>
                                            <Link to="#"><strong>Inovação</strong></Link>
                                            <Link to="#"><strong>SEO</strong></Link>
                                            <Link to="#"><strong>Rentabilidade</strong></Link>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-12' style={{paddingTop: '3%'}}>
                    <div className='conteudo-banner-container themesflat-headings style-1 text-center clearfix'>
                        <h2 className='heading'>COMO FAZEMOS</h2>
                    </div>
                </div> */}

                {/* <div className="container">
                    <div className="row equalize sm-equalize do-image-container">
                        <div className='col-md-6 '>
                            <div className='do-image'>
                                <img src='../../../../assets/img/slider/slider-bg-40.jpg'/>
                            </div>
                        </div>                       
                        <div className="col-md-6 bg-light-grey">
                       
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">MUITO MAIS QUE UMA SOFTWARE HOUSE</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />                                               
                                    <p className="margin-top-30">We have successfully completed projects in numerous states across the continental United States. Autora Construction Services has a proven track record of:</p>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                        <div className="inner">
                                            {
                                                this.state.inner.map(data => (
                                                    <span className="item" key={data.id}>
                                                        <span className="icon"><i className={data.icon} /></span>
                                                        <span className="text">{data.text}</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                <div className="elm-button">
                                    <Link to="#" className=" default-button" style={{marginRight: 30}}>QUEM SOMOS</Link>
                                    <Link to="#" className=" default-button">ORÇAMENTO</Link>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>



                        
                    </div>
                </div> */}
            </div>
        );
    }
}

export default About;