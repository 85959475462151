import React from "react";
import {Link} from 'react-router-dom'
import helmet from "../../data/helmet";

const HeaderNew = (props) => {

    return(
        <div id="site-header" className="header-container is-fixed" >
            <div className="header-content-container">
                <div className="header-left-container">
                    <div>
                        <Link to="/" onClick={() => {window.location.href="/"}}><img className="header-left-logo" src="assets/img/o.png" height={35} width={35}/></Link>
                    </div>
                    <div>
                        <h1 className="header-left-title">{props.data}</h1>
                    </div>
                </div>
                <div className="header-right-container">
                    <div className="header-right-social">
                        <a style={{marginTop: -2}} href='https://www.linkedin.com/company/ornibr/' target={'_blank'} rel="noreferrer"><img alt='ícone linkedin' src='assets/img/linked_in.png' width={18} height={18}/></a>
                        <a href='https://www.youtube.com/channel/UCx8F-rRdQOTCCqsoyjsfNDw' target={'_blank'} rel="noreferrer"><img alt='ícone youtube' src='assets/img/youtube.png' width={25} height={25}/></a>
                        <a href='https://www.instagram.com/orni.com.br/' target={'_blank'} rel="noreferrer"><img alt='ícone instagram' src='assets/img/instagram.png' width={18} height={18}/></a>    
                    </div>
                    <div>
                        <a className="header-right-link">Área do Cliente</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderNew;