import React, { Component } from 'react';
import menus from '../layouts/menu';

import { FormContact } from '../layouts/contact';

import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import formData from './form_data';
import FooterNew from '../layouts/general/Footer-2';

class Contact02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Contact",
                }
            ],
            
        }
    }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={menus} key={idx}/>
                                ))
                            }
                            
                        </div>
                        
                    </div>

                    <FormContact data={formData}/>
                    <FooterNew />
                    {
                        this.state.headers.map((data,idx) =>(
                            <BottomBar data={data} key={idx}/>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Contact02;
