import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import TabProject from '../general/TabProject';
import FormContact from '../contact/FormContact';
import formData from './form_data';

class Investimento extends Component {

    render() {
        return (
            <div className='coming-body col-md-12'style={{backgroundColor: "#090e1c"}}>

                <img className="capte-banner" alt="Fundo espacial com número 404 no centro" src="assets/img/banner/invista.webp" />
                
                <h1 className='capte-banner-text'>INVISTA</h1>  

                <div className='capte-video-container' style={{backgroundColor: "#090e1c"}}>
                    <p className='capte-intro-text'>Nosso objetivo é ajudar a construir um futuro melhor através do poder da inovação tecnológica e do empreendedorismo. Junte-se a nós e seja parte da próxima grande história de sucesso.</p>
                </div>

                <div className="row-project clearfix" style={{backgroundColor: "#090e1c"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                                    <div className="position-relative">
                                        <div className="themesflat-headings style-1 clearfix">
                                            <h2 className="heading " style={{textAlign: 'center'}}>NOSSOS PROJETOS</h2>
                                        </div>
                                        {/* <ul className="themesflat-filter style-1 filter-absolute clearfix">
                                            <li className="active">
                                                <Link to="#" data-filter="*">All</Link>
                                            </li>
                                            <li>
                                                <Link to="#" data-filter=".green">GREEN HOUSE</Link>
                                            </li>
                                            <li>
                                                <Link to="#" data-filter=".architecture">ARCHITECTURE </Link>
                                            </li>
                                            <li>
                                                <Link to="#" data-filter=".construction">CONSTRUCTION </Link>
                                            </li>
                                            <li>
                                                <Link to="#" data-filter=".villa">VILLA</Link>
                                            </li>
                                            <li>
                                                <Link to="#" data-filter=".building">BUILDING</Link>
                                            </li>
                                        </ul> */}
                                    </div>                 
                                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={35} data-smobile={35} />
                                <TabProject />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                <a href="https://api.whatsapp.com/send?phone=5551989065423&text=" target={'_blank'} className='default-button' rel="noreferrer" >ENTRE EM CONTATO CONOSCO PELO WHATSAPP</a>

                </div>

                <FormContact data={formData}/>


            </div>
        )
    }
}

export default Investimento;