import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import FormContact from '../contact/FormContact';
import dataAreas from './data_areas';
import formData from './form_data';

class Captacao extends Component {

    render() {
        return (
            <div className='coming-body col-md-12'>

                <img className="capte-banner" alt="Fundo espacial com número 404 no centro" src="assets/img/banner/capte.png" />
                
                <h1 className='capte-banner-text'>CAPTE</h1>  



                <div className='capte-video-container'>
                
                    <p className='capte-intro-text'>Bem-vindo à plataforma Orni de busca de funding! Estamos aqui para conectar investidores apaixonados a projetos inovadores com empreendedores talentosos. Se você está procurando investir em uma ideia brilhante ou está em busca de financiamento para sua própria empresa, você veio ao lugar certo.</p>

                    {/* <video controls className='capte-video'>
                        <source src='https://www.youtube.com/watch?v=zDsxbnCds28' type='video/mp4' />
                    </video> */}
                    {/* <iframe className='capte-video' src="https://www.youtube.com/embed/gzY9xw4IMQU?muted=1" ></iframe> */}
                </div>
                
                <div className='capte-path-container'>
                {/* <h2>teste teste explicações coisinhas assuntinhos e afins</h2> */}
                    <img src='assets/img/path1.png' className='capte-path-image'/>
                    <img src='assets/img/path2.png' className='capte-path-image' />
                </div>

                <div className='capte-areas-container'>
                    
                    <div className='capte-areas'>
                        {
                            dataAreas.map(data => (
                                <div  className="owl-carrosel" >
                                    <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                                    <div className="widget widget_lastest">
                                        <div className="conteudo-imagem">
                                            <img src={data.img} alt={data.img_alt} width={300} height={34} />
                                        </div>
                                        
                                        <h2 className="conteudo-title widget-title"><span>{data.title}</span></h2>
                                        <ul className="lastest-posts data-effect clearfix">
                                            <li className="clearfix">
                                                <div className="text">
                                                    <h3 style={{height: 70, width: 310}}>{data.subtitle}</h3>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            ) )
                        }
                    </div>
            

                </div>

                <div className='capte-whatsapp-container'>
                <a href="https://api.whatsapp.com/send?phone=5551989065423&text=" target={'_blank'} className='default-button' rel="noreferrer" >ENTRE EM CONTATO CONOSCO PELO WHATSAPP</a>
                </div>

                <FormContact data={formData}/>

            </div>
        )
    }
}

export default Captacao;