import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Conteudo extends Component{
    render() {
        return(
            <div className='themesflat-row mbl-conteudo' style={{marginBottom: 60}}>
                <div className='conteudo-banner-container themesflat-headings style-1 text-center clearfix'>
                    <h1 className='heading'>ORNI CONTEÚDOS</h1>
                    <h6> Tudo de que participamos, fazemos ou aprendemos vem pra cá.<br/> Fique sempre ligado para não perder nenhuma novidade!</h6>
                </div>
                
                <div className="col span_1_of_3">
                    <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                    <div className="widget widget_lastest">
                        <div className="conteudo-imagem">
                            <img src="assets/img/layer_2.webp" alt="imagealt" width={270} height={34} />
                        </div>
                        
                        <h2 className="conteudo-title widget-title"><span>ARTIGO</span></h2>
                        <ul className="lastest-posts data-effect clearfix">
                            <li className="clearfix">
                                <div className="text">
                                    <h3 style={{height: 70}}>Meus critérios na seleção de uma equipe de sucesso.</h3>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div>
                                   <a href='https://www.linkedin.com/feed/update/urn:li:ugcPost:6994342351187927040?updateEntityUrn=urn%3Ali%3Afs_updateV2%3A%28urn%3Ali%3AugcPost%3A6994342351187927040%2CFEED_DETAIL%2CEMPTY%2CDEFAULT%2Cfalse%29' target={'_blank'} className='default-button' rel="noreferrer">LEIA</a>
                                </div>
                            </li>
                        </ul>
                    </div>{/* /.widget_lastest */}
                </div>
                <div className="col span_1_of_3">
                    <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                    <div className="widget widget_lastest">
                        <div className="conteudo-imagem">
                            <img src="assets/img/acate_miniatura.webp" alt="imagealt" width={270} height={34} style={{aspectRatio: '1/1', objectFit: 'cover'}}/>
                        </div>
                        <h2 className="widget-title"><span>RELATO</span></h2>
                        <ul className="lastest-posts data-effect clearfix">
                            <li className="clearfix">
                                <div className="text">
                                    <h3 style={{height: 70}}>Conexão: Orni+ Instituto Caldeira + Acate + Banana Startups 👨‍💻</h3>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div>
                                    <a href='https://www.linkedin.com/posts/ornibr_tbt-tecnologiabrasil-acate-activity-6978719931018153984-zNTl?utm_source=share&utm_medium=member_desktop' target={'_blank'} className='default-button' rel="noreferrer" >LEIA</a>
                                </div>
                            </li>
                        </ul>
                    </div>{/* /.widget_lastest */}
                </div>
                <div className="col span_1_of_3">
                    <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                    <div className="widget widget_lastest">
                        <div className="conteudo-imagem">
                            <img src="assets/img/rebranding-miniatura.webp" alt="imagealt" width={270} height={34} />
                        </div>
                        <h2 className="widget-title"><span>POST</span></h2>
                        <ul className="lastest-posts data-effect clearfix">
                            <li className="clearfix">
                                <div className="text">
                                    <h3 style={{height: 70}}>Rebranding Orni</h3>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div>
                                <a href='https://www.linkedin.com/posts/ornibr_orni-ornibr-metaverso-activity-6974059279586607104-z4LT?utm_source=share&utm_medium=member_desktop' target={'_blank'} className='default-button' rel="noreferrer" >LEIA</a>
                                </div>
                            </li>
                        </ul>
                    </div>{/* /.widget_lastest */}
                </div>
                <div className="col span_1_of_3">
                    <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                    <div className="widget widget_lastest">
                        <div className="conteudo-imagem">
                            <img src="assets/img/ux_ui_miniatura.webp" alt="imagealt" width={270} height={34} />
                        </div>
                        <h2 className="widget-title"><span>ARTIGO</span></h2>
                        <ul className="lastest-posts data-effect clearfix">
                            <li className="clearfix">
                                <div className="text">
                                    <h3 style={{height: 70}}>Qual a diferença entre UX e UI design?</h3>
                                </div>
                            </li>
                            <li className="clearfix">
                                <div>
                                <a href='https://www.linkedin.com/posts/ornibr_uxdesign-uidesign-userexperience-activity-6990654456618979328-G_lR?utm_source=share&utm_medium=member_desktop' target={'_blank'} className='default-button' rel="noreferrer" >LEIA</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            
            
        );
    }
}

export default Conteudo;