const images = {
    appleWhite: "assets/img/apple-white.png",

    appleBlack: "assets/img/apple-black.png",
    
    androidWhite: "assets/img/android-white.png",
    
    androidBlack: "assets/img/android-black.png",
    
    istqb: "assets/img/ISTQB.png",
    
    cellphoneWhite: "/assets/img/cellphone-white.png",
    
    cellphoneBlack: "/assets/img/cellphone-black.png",
    
    tabletWhite: "/assets/img/tablet-white.png",
    
    tabletBlack: "/assets/img/tablet-black.png",

    desktopWhite: "/assets/img/desktop-white.png",

    desktopBlack: "/assets/img/desktop-black.png"
}

export default images;
