import React, { Component } from 'react';

class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partner: [
                {
                    id: 1,
                    img: 'assets/img/partners/artboard_1.webp',
                    link: 'https://www.modular.com.br/'
                },
                {
                    id: 2,
                    img: 'assets/img/partners/artboard_2.webp',
                    link: 'http://www.onumulheres.org.br/ganha-ganha/'
                },
                {
                    id: 3,
                    img: 'assets/img/partners/artboard_3.webp',
                    link: 'https://brasil.un.org/'
                },
                {
                    id: 4,
                    img: 'assets/img/partners/artboard_4.webp',
                    link: 'http://www.fmss.org.br/'
                },
                {
                    id: 5,
                    img: 'assets/img/partners/artboard_5.webp',
                    link: 'https://www.gov.br/pt-br'
                },
                {
                    id: 6,
                    img: 'assets/img/partners/artboard_6.webp',
                    link: 'https://www.gruporbs.com.br/'
                },
                {
                    id: 7,
                    img: 'assets/img/partners/artboard_7.webp',
                    link: 'https://www.pmerechim.rs.gov.br/'
                },
                {
                    id: 8,
                    img: 'assets/img/partners/artboard_8.webp',
                    link: 'http://www.ufrgs.br/ufrgs/inicial'
                },
                {
                    id: 9,
                    img: 'assets/img/partners/artboard_9.webp',
                    link: 'https://www.upf.br/'
                },
                {
                    id: 10,
                    img: 'assets/img/partners/artboard_10.webp',
                    link: 'https://clinicagabrielli.com.br/'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row-partner client-background" style={{backgroundColor: '#090e1c'}}>
                 <div className="themesflat-spacer clearfix" data-desktop={83} data-mobile={60} data-smobile={60} />
                <div className="container">
                <div className='conteudo-banner-container themesflat-headings style-1 text-center clearfix'>
                    <h1 className='heading'>CONHEÇA ALGUNS<br/>DE NOSSOS CLIENTES</h1>
                    <div className="themesflat-spacer clearfix" data-desktop={43} data-mobile={40} data-smobile={40} />
                    <h4><br/>Trabalhar com a ORNI é dar forma às ideias, construindo novas realidades. <br/> <br/> <strong>Aqui usamos a tecnologia para gerar lucros.</strong></h4>
                </div>
                    <div className="row">
                        <div className="col-md-12">
                           
                            <div className="themesflat-carousel-box clearfix" data-gap={6} data-column={6} data-column2={3}
                                data-column3={2} data-auto="true">
                                <div className="owl-carousel owl-theme">
                                    {
                                        this.state.partner.map(data =>(
                                            <div className="themesflat-partner style-1 align-center clearfix" key={data.id}>
                                                <div className="partner-item">
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <a href={data.link} target={'_blank'} rel="noreferrer">
                                                                <img src={data.img} alt="altimage"
                                                                className="partner-default" />
                                                            </a>
                                                            
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={66} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={50} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default Partner;