const bannerApp = 
    {
        title: "Enfrentando problemas com software e time de tecnologia?",
        subtitle: "Nossa equipe de especialistas em tecnologia tem somados mais de 110 anos de experiência trabalhando com tecnologia de ponta e negócios, prontos para resolver todos os seus problemas imediatamente.",
        btn1: "Solicite Orçamento",
        btn1Link: "https://api.whatsapp.com/send?phone=5551989065423&text=",
        btn2: "fale com um especialista",
        btn2Link: "https://api.whatsapp.com/send?phone=5551989065423&text=",
        img: "assets/img/lp-banner.png",
        bgColor: "#000",
        right: "img",
        rightClass: "lp-banner-conteudo-right-container",
        leftButtonClass: "lp-banner-conteudo-left-button-container",
        formLink: "https://orni.activehosted.com/f/embed.php?id=38",
        formClass: ""
    }


export default bannerApp;