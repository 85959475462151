import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Caldeira extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial : [
                {
                    id: 1,
                    srcimg: 'assets/img/cald-1.webp',
                    altText: 'fachada instituto caldeira'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/cald-2.webp',
                    altText: 'jardim interno instituto caldeira'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/cald-3.webp',
                    altText: 'área da caldeira instituto caldeira'
                }
            ]
        }
    }
    
    
    render() {
        return (
            <div className="row-about client-background" style={{backgroundColor: '#180156'}}>
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        
                        <div className="col-md-6">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h1 className='heading'>INOVAÇÃO E TECNOLOGIA NO</h1>
                                    <h1 className='heading'>INSTITUTO CALDEIRA</h1>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> 
                                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />                                              
                                    <h4>Fazemos parte de um dos maiores HUBs de inovação e tecnologia da América Latina. <br/><br/> Aqui a inovação fervilha a todo momento e assim criamos um ecossistema de parcerias que possibilitam gerar rentabilidade.</h4>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                       <a href='https://www.institutocaldeira.org.br' target={'_blank'} rel="noreferrer"> <img alt='ícone instituto caldeira' src='assets/img/logo-caldeira.webp'/> </a>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>

                        <div className="col-md-6 half-background" >
                            {/*  */}
                            <div className="themesflat-carousel-box has-arrows arrow-center arrow-circle offset-v-24 clearfix"
                                    data-gap={30} data-column={1} data-column2={1} data-column3={1} data-auto="true" >
                                    <div className="owl-carousel owl-theme">
                                        {
                                            this.state.testimonial.map(data => (
                                                <div className="themesflat-testimonials style-1 max-width-70 align-center has-width w100 circle border-solid clearfix" key={data.id}>
                                                    <div className="testimonial-item caldeira-banner">
                                                        <div>
                                                            <blockquote className="text">
                                                                <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />
                                                                <a href='https://www.institutocaldeira.org.br' target={'_blank'} rel="noreferrer"><img alt={data.altText} src={data.srcimg} className="caldeira-image" /></a> 
                                                            </blockquote>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                            {/* <div className="themesflat-spacer clearfix" data-desktop={55} data-mobile={60} data-smobile={60} /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Caldeira;