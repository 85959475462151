const dataSlide = [
    {
        id: 1,
        title: "ORNI CLOUD",
        description: "Maximize sua eficiência na nuvem com nossa consultoria personalizada em AWS. Otimize custos, gerencie sua infraestrutura e alcance o sucesso em projetos em nuvem. Foque no seu negócio enquanto cuidamos dos aspectos técnicos.",
        img: "assets/img/infra-logo.png",
        class: "box a1",
        link: "https://infra.orni.com.br"
    },
    {
        id: 2,
        title: "VENTURE BUILDER",
        description: "Vamos levar a melhor infraestrutura do mundo disponível no mercado para todos os nossos clientes e parceiros, com um preço acessível e competitivo em relação à concorrência. Consulte a nossa consultoria de dimensionamento de infraestrutura e o plano padrão de hospedagem.",
        img: "assets/img/venture-logo.png",
        class: "box a2",
        link: "https://api.whatsapp.com/send?phone=5551989065423&text="
    },
    {
        id: 3,
        title: "SOFTWARE HOUSE",
        description: "Utilizamos conceitos técnicos e tecnologias de alta performance, com foco na qualidade e escalabilidade de cada projeto. Possuímos certificação internacional em qualidade de software pelo ISTQB, além de lideranças especialistas em análise e engenharia de software, UX/UI, métodos ágeis, gestão de projetos e infraestrutura AWS.",
        img: "assets/img/software-logo.png",
        class: "box a3",
        link: "https://api.whatsapp.com/send?phone=5551989065423&text="
    }
  
]

export default dataSlide;