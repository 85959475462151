import React, { Component } from "react";
import dataSlide from "./data_slide";

class Laboratorio extends Component {
  render() {
    return (
      <div className="services-container col-md-12">
        <div className="services-banner-container">
          <div className="labs-banner-text-container">
            <img
              className="labs-banner-text-image"
              alt=""
              src="assets/img/orni_logo_orni_hlogo_white.webp"
            />
            <h2 className="labs-banner-text">SERVIÇOS</h2>

            <p className="services-banner-subtitle">
            Nossas soluções tecnológicas em cloud são construídas com base nos 6 pilares da AWS: segurança, confiabilidade, desempenho, custo, operações e otimização, oferecendo servidores, armazenamento e conexões de rede de alta qualidade. O venture building consiste na criação e desenvolvimento de novas empresas, fornecendo suporte para o seu crescimento. Nossa software house é especializada no desenvolvimento de software personalizado para atender às suas necessidades.
            </p>

            <div className="services-carousel-container">
              <div className="accordion">
                {dataSlide.map((data) => (
                  <div
                    className={data.class}
                    key={data.id}
                  >
                    <div className="textAccor-container">
                      {/* <div className="services-logo-container">
                        <img
                          className="services-logo"
                          src={data.img}
                        />
                      </div> */}
                      <div className="textAccor">
                        <h2 className="accorTitle">{data.title}</h2>
                        <p className="pAccor">{data.description}</p>
                        <a href={data.link} target={"_blank"} rel="noreferrer">
                          <div className="services-button" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Laboratorio;
