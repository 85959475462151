import React, { Component } from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error-container">
        <img className="error-image" alt="Fundo espacial com número 404 no centro" src="assets/img/erro.webp" />
        
        <div className="error-text-container">
            <h1 className="error-text">PARECE QUE VOCÊ ESTÁ PERDIDO NO ESPAÇO</h1>
        </div>

        <div className="error-button-container">
            <div>
                <Link to="/" onClick={() => {window.location.href="/"}} className="default-button">VOLTE AO INÍCIO</Link>
            </div>
        </div>

    </div>
  );
};

export default Error;
