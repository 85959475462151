import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Termos extends Component {
    render() {
        return (
            <div className='terms-container'>
                <div>
                    <div className="themesflat-spacer clearfix" />
                    <h2 className='text-center'>Termos de Uso<br/>Orni Tecnologia da Informação LTDA e todos seus Serviços</h2>
                    <p className='terms-text' >
                        1. Do uso dos serviços da Orni:
                        <br/>
                        <br/>
                        1.1 - Segue a definição de alguns termos utilizados neste documento, para sua melhor compreensão:
                        <br/>
                        -ORNI: empresa que presta o serviço para o cliente;
                        <br/>
                        -Cliente: pessoa jurídica ou pessoa física que contrata com a ORNI, ou
                        qualquer pessoa vinculada a estas, que alimenta o software desenvolvido
                        pela ORNI;
                        <br/>
                        -Usuário: é o internauta, que acessa o Site, Loja Virtual ou Aplicativo
                        desenvolvido pela ORNI, através da internet;
                        <br/>
                        -Loja Virtual: página na Internet que utiliza software de gerenciamento de
                        pedidos, na qual empresas oferecem e vendem seus serviços ou produtos;
                        <br/>
                        -Aplicativo: ou “app”, é um software desenvolvido para ser instalado em
                        um dispositivo eletrônico móvel:
                        <br/>
                        <br/>
                        2. Do uso dos serviços da Orni:
                        <br/>
                        <br/>
                        2.1 - É informando nesta página de endereçamento eletrônico os termos e condições que regem os serviços da empresa ORNI TECNOLOGIA DA INFORMAÇÃO LTDA. Por favor, leia atentamente as condições abaixo para que você usufrua dos serviços prestados por nossa empresa, e lembre-se que ao utilizar estes serviços você estará declarando ter ciência destes TERMOS DE CONDIÇÕES DE USO DA ORNI TECNOLOGIA DA INFORMAÇÃO Ltda. aqui descrito e estará concordando com todas as cláusulas e condições. Caso você não concorde com quaisquer disposição dos TERMOS DE CONDIÇÕES DE USO DA ORNI TECNOLOGIA DA INFORMAÇÃO, por favor, não utilize os nossos serviços, e comunique através do email: contato@orni.com.br.
                        <br/>
                        2.2 - A ORNI é a desenvolvedora e portadora dos direitos autorais e de propriedade intelectual do Painel de Controle de Gerenciamento do Site, Loja Virtual ou Aplicativo este sendo fornecido ao cliente para o seu uso, sob condições específicas descritas nos presentes termos de uso.
                        <br/>
                        2.3 -A ORNI, através de seu CNPJ devidamente registrado e regular, possui o Código Nacional de Atividade Econômica (CNAE) número 62.02-3-00, o qual permite, na legislação brasileira, o licenciamento de programas de computador customizáveis.
                        <br/>
                        2.4 - O CLIENTE se compromete a não reproduzir, duplicar, copiar ou explorar os serviços prestados, softwares utilizados e/ou materiais disponibilizados pela empresa, para quaisquer fins não expressamente autorizados no presente termo, sem sua autorização por escrito.
                        <br/>
                        2.5- É comercializado entre a ORNI e o CLIENTE, horas técnicas contratadas para a customização do Painel de Controle de Gerenciamento do Site, Loja Virtual ou Aplicativo a fim de que o layout, configurações, marcas, conteúdo, sistema, funcionamento, requisitos de software fiquem de agrado ao cliente.
                        <br/>
                        2.6- Acima de 10 horas técnicas contratadas, é realizado um contrato de prestação de serviço de customização de software, devidamente numerado, de posse do CLIENTE e da ORNI.
                        <br/>
                        2.7- O CLIENTE fica ciente que a ORNI trabalha com “software como serviço” (popularmente conhecido como ‘SaaS’ - Software as a service), principalmente o seu Painel de Controle de Gerenciamento do Site, Loja Virtual ou Aplicativo, que não deve ser transportado, movido ou recortado para qualquer lugar sem a devida permissão escrita da ORNI.
                        <br/>
                        2.8- A qualquer momento poderão ser incluídas novas ferramentas, produtos, serviços e atividades na ORNI, bem como excluídas as já existentes, sem qualquer aviso prévio aos CLIENTES.
                        <br/>
                        2.9- Toda e qualquer nova ferramenta, produto, serviço ou atividade incluída na ORNI estará, automaticamente, vinculada e subordinada a todos os termos e condições do presente contrato.
                        <br/>
                        <br/>
                        3. Da propriedade intelectual e da responsabilidade pelas informações:
                        <br/>
                        <br/>
                        3.1- A ORNI compromete-se a não utilizar as informações fornecidas pelo CLIENTE para a realização de quaisquer atividades ilícitas, mas somente para aquelas expressamente permitidas pela legislação brasileira e/ou pelo presente instrumento.
                        <br/>
                        3.2- Todos e quaisquer dados ou informações fornecidas pelo CLIENTE para a ORNI poderão ser imediatamente reveladas, em cumprimento à ordem judicial ou administrativa.
                        <br/>
                        3.3- Todo material e serviço encontrado nos serviços da ORNI (textos, imagens, áudio, tecnologia, logotipos, slogans, marcas, expressões de propaganda, domínios, nomes comerciais, obras intelectuais), bem como os softwares que viabilizam as atividades, são de exclusiva propriedade da ORNI, que detém todos os direitos autorais, licenciamentos e direitos de propriedade, marca ou patente a eles relativos (exceto aqueles inseridos pelo CLIENTE). Qualquer violação dos direitos de propriedade pelo CLIENTE resultará na sua responsabilidade, direta e pessoal, pelos atos praticados, nos termos da lei e com a aplicação das sanções cabíveis cíveis e criminais.
                        <br/>
                        3.4- O CLIENTE se compromete a não reproduzir, duplicar, copiar ou explorar os serviços prestados, softwares utilizados e/ou materiais disponibilizados pela empresa, para quaisquer fins não expressamente autorizados no presente termo, sem sua autorização por escrito.
                        <br/>
                        3.5- A ORNI pode fornecer links de acesso a sites de terceiros no software desenvolvido, porém a presença destes links para outros sites não constitui um endosso do conteúdo, nem das práticas de negócios dessas entidades. Por isso, nenhuma espécie de responsabilidade, seja ela de acessibilidade, veracidade, legalidade do conteúdo ou outra qualquer, poderá ser imputada à empresa ORNI, quando o CLIENTE ou o usuário (por meio do site desenvolvido pela ORNI), entrar em sites ou links de propriedade, administração e/ou controle de terceiros, tais como patrocinadores, parceiros, prestadores de serviços em geral, demais usuários, etc.
                        <br/>
                        3.6- Todo e qualquer material, dado ou informação, incluídos pelo CLIENTE em qualquer página de algum produto da ORNI, será de plena e total responsabilidade do CLIENTE, que responderá exclusivamente por quaisquer prejuízos que sejam causados a terceiros e/ou à ORNI.
                        <br/> 
                        3.7- O CLIENTE autoriza o envio e a utilização dos seus dados para empresas parceiras; bem como se declara ciente e concorda que, ao transmitir e/ou enviar a ORNI qualquer material, informação e/ou dado, estará automaticamente cedendo o seu direito de uso e divulgação à ORNI, e que está ciente de que também poderá ter suas informações indexadas em buscadores de conteúdo, tal como o Google.
                        <br/>
                        3.8- Quando não for de sua própria e/ou exclusiva autoria, o CLIENTE deverá possuir a expressa autorização do(s) legítimo(s) titular(es) de toda e qualquer espécie de material, informação e/ou dado que disponibilize para o uso ou sua inclusão no software, e para a sujeição aos termos do presente contrato. Em qualquer caso, o CLIENTE remetente será o único responsável perante a ORNI e terceiros, nos termos da lei, por quaisquer reclamações ou reivindicações de terceiros concernentes à autoria, uso indevido ou prejuízos relacionados ao uso de referido material.
                        <br/>
                        <br/>
                        4. Das condições gerais:
                        <br/>
                        <br/>
                        4.1- O CLIENTE, ao utilizar os serviços da ORNI aceita expressamente todas as cláusulas do presente instrumento, bem como as atividades e serviços que existem e que venham a existir em produtos da ORNI, reconhecendo-as como inocentes, saudáveis, de boa-fé e                            
                        não-ofensivas, aceitando suas regras, instruções e condições constantes do próprio site, de forma absoluta e irrestrita, garantindo que não se sentirá de qualquer forma ultrajado, ofendido ou prejudicado pelas mesmas.
                        <br/>
                        4.2- Caso a ORNI venha a criar alguma nova atividade, que de alguma forma o CLIENTE não considere inocente, saudável, de boa-fé ou não-ofensiva ou ainda, que possa eventualmente ofendê-lo ou prejudicá-lo, o CLIENTE obriga-se a cessar o uso dos serviços.
                        <br/>
                        4.3- O CLIENTE, ao cometer infrações, é ciente que multas rescisórias contratuais poderão ser aplicadas, se previamente estiverem estabelecidas em contrato.
                        <br/>
                        4.4- A ORNI não se responsabiliza nem pelo conteúdo, nem pelas políticas e práticas de privacidade dos sites que apontam para produtos da empresa, e nem daqueles sites para os quais apontamos.
                        <br/>
                        4.5 -O CLIENTE declara-se plenamente ciente que:
                        As rotinas de Backup são de inteira responsabilidade do CLIENTE, sendo a
                        ORNI um facilitador deste serviço.
                        É responsabilidade do CLIENTE pagar os valores de acordo com suas
                        obrigações, em sua integralidade, independentemente de sua utilização.
                        A renovação de algumas prestações de serviços ocorre automaticamente. O fim da vigência da prestação dos serviços se dará através de uma solicitação e/ou notificação da parte do CLIENTE para a ORNI através do e-mail:
                        suporte@orni.com.br, com um mês de antecedência.
                        Poderão ser aplicadas multas às faturas, caso exista o atraso de pagamento
                        por parte do CLIENTE.
                        O serviço utilizado pelo CLIENTE poderá ser SUSPENSO por atraso de
                        pagamento de qualquer fatura emitida.
                        Caso exista atraso de pagamento de alguma fatura emitida ao CLIENTE, os
                        dados contidos no serviço poderão ser excluídos a qualquer momento, sem
                        nenhum aviso prévio.
                        O CLIENTE concorda e aceita que as transações que ocorrerem no seu
                        próprio domínio, em contrato denominado, poderão ser canceladas pelo usuário ou não aceitas pelo CLIENTE, e neste caso, não são incluídas no balancete (saldo a receber) da ORNI, caso exista.
                        O CLIENTE concorda que todas as transações financeiras que ocorrerem no seu próprio domínio, em contrato específico, sofrerão a cobrança de taxa de administração do gateway de pagamento;
                        O CLIENTE, por este ato, também aceita as Regras de Uso do gateway de pagamento utilizado pela ORNI.
                        <br/>
                        4.6- O cliente, ao contratar nossos serviços, tem conhecimento e tem domínio, minimamente, de conhecimentos de informática e dos processos do qual está contratando, como uma Loja virtual, por exemplo;
                        A ORNI comercializa horas especializadas para um projeto, e as horas excedentes são cobradas à parte, de forma pré-paga. Caso não realize o pagamento, seu projeto é suspenso. Caso retome, a ORNI estabelecerá um cronograma de atendimento;
                        Ao contratar um serviço de marketing digital não necessariamente significa que irá vender muito. A venda depende de vários aspectos. O serviço de marketing da ORNI consiste em um serviço de consultoria em horas.
                        <br/>
                        4.7- O término da prestação de serviço, pela ORNI, se dá com o fim da quantia de horas contratadas.
                        <br/>
                        4.8- As horas informadas, tanto na proposta, quanto no contrato, são apenas uma estimativa de horas que serão gastas em um projeto, para se estimar um valor do projeto.
                        A conclusão do projeto é subjetivo, ou seja, o que está concluso para a ORNI, pode não estar para o CLIENTE; neste modelo de negócio, a ORNI comercializa horas de desenvolvimento, e de nenhuma forma comercializa desenvolvimentos de escopo fechado.
                        <br/>
                        <br/>
                        5. Da Lei Aplicável e Foro:
                        <br/>
                        <br/>
                        5.1- As presentes condições são regidas única e exclusivamente pelas leis da República Federativa do Brasil e qualquer discussão judicial que surja tendo por base sua interpretação ou aplicação deverá ser julgado por tribunais brasileiros, estando desde logo eleito o foro da Cidade de Porto Alegre, Rio Grande do Sul, Brasil, por mais privilegiado que outro seja ou possa vir a ser. </p>
                </div>
             </div>
            
        )
    }
}

export default Termos;