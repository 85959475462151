import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class VentureBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial : [
                {
                    id: 1,
                    srcimg: 'assets/img/cald-1.webp',
                    altText: 'fachada instituto caldeira'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/cald-2.webp',
                    altText: 'jardim interno instituto caldeira'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/cald-3.webp',
                    altText: 'área da caldeira instituto caldeira'
                }
            ]
        }
    }
    
    
    render() {

        
        return (
            <div className="row-about client-background bg-white" style={{ paddingBottom: '5%'}}>
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        
                        <div className="col-md-6">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h1 className='heading lt-black'>ORNI - VENTURE BUILDER</h1>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> 
                                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />                                              
                                    <h4 className='lt-black'>Somos a Orni Global, a venture builder líder em tecnologia de ponta para gerar lucro e criar grandes cases de sucesso. <br/><br/> Nossos serviços de venture builder incluem:<br/><br/>Criação de projetos técnicos completos;<br/>Consultoria estratégica;<br/>Aceleração e captação de investimento para sua empresa decolar;<br/>Soluções de tecnologia de ponta - como uma fábrica de software munida do melhor do UX/UI Design e planejamento de marketing e lançamento.
                                    </h4>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>

                        <div className="col-md-6 venture-spotify-container">
                                    <h1 className='ornicast lt-black'>ESCUTE NOSSO PODCAST SOBRE VENTURE BUILDING</h1>
                                    <iframe  src="https://open.spotify.com/embed/episode/51Q8gqIgrTO7is1DygN98s?utm_source=generator" width="80%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                                    <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />   
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VentureBuilder;