import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Laboratorio from '../layouts/labs/Laboratorio';
import menus from '../layouts/menu';
import menuApp from '../layouts/menuApp';

import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index';
import FooterNew from '../layouts/general/Footer-2';

class Labs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Em construção",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Coming Soon',
                    heading: 'Em Breve'
                }
            ]
        }
    }
    render() {
        return (
            <div className="header-fixed page sidebar-left header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition" style={{backgroundColor: 'rgb(8, 14, 27'}}>
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            <Header data={menus}/>
                        </div>
                       
                        <Laboratorio/>
                         
                        <FooterNew />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Labs;