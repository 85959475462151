const bannerhome1 = [
  {
    id: 2,
    originalSrc: "assets/img/slider/slider-bg-20_1.JPG",
    mobileSrc: null,
    classnames:
      "tp-caption tp-resizeme text-white font-heading font-weight-600 text-center",
    classtext:
      "tp-caption tp-resizeme text-white font-heading font-weight-700 text-center",
    datax: "['center','center','center','center']",
    datahset: "['0','0','0','0']",
    datavset1: "['-150','-150','-150','-150']",
    datavset2: "['-43','-43','-43','-43']",
    datavset3: "['50','50','50','50']",
    datavset: "['156','156','156','156']",
    classbtn: "tp-caption text-center",
    text1: "SEU BATE PAPO SOBRE COM DATA E HORA MARCADAS SOBRE",
    text2: "INOVAÇÃO, NEGÓCIOS E TECNOLOGIA",
    text3: "CONHEÇA O ORNICAST",
    link: "https://open.spotify.com/show/4TmRUZ3h6eEFHon2uYYTkF?si=6e061224893a40a3"
  },
  {
    id: 3,
    originalSrc: "assets/img/slider/slider-bg-20.webp",
    mobileSrc: null,
    classnames:
      "tp-caption tp-resizeme text-white font-heading font-weight-600 text-right",
    classtext:
      "tp-caption tp-resizeme text-white font-heading font-weight-700 text-right",
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['0','0','0','0']",
    datavset2: "['50','50','50','50']",
    datavset3: "['130','130','130','130']",
    datavset: "['206','206','206','206']",
    classbtn: "tp-caption text-right",
    text1: "MUITO ALÉM DE UMA DESENVOLVEDORA DE APLICAÇÕES",
    text2: "TRANSFORMAMOS IDEIAS",
    text3: "EM PROJETOS RENTÁVEIS"
  }
];

export default bannerhome1;
