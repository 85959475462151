const footerLinks = [
    {
        id: 0,
        title: "Seja Orni",
        link: "/contato",
        icon: ""
    },
    {
        id: 1,
        title: "Fábrica de Software",
        link: "/software-house",
        icon: ""
    },
    {
        id: 2,
        title: "Fábrica de Aplicativos",
        link: "/criar-aplicativo-porto-alegre",
        icon: ""
    },
    {
        id: 3,
        title: "Projeto Técnico",
        link: "https://projetos.orni.com.br",
        icon: ""
    },
    {
        id: 4,
        title: "Invista em Projetos",
        link: "/invista",
        icon: ""
    },
    {
        id: 5,
        title: "OrniCast",
        link: "https://open.spotify.com/show/4TmRUZ3h6eEFHon2uYYTkF?si=6e061224893a40a3",
        icon: ""
    },
]

export default footerLinks;
