import React from 'react';
import useScript from '../../hooks/useScript'

const LandingPageBanner = (props) => {

    const datas  = props.data;
    console.log(datas);

    if (datas.right === "form") {
        useScript(datas.formLink);
    }

    return (
        
        <div style={{backgroundColor: datas.bgColor}} className="lp-banner-container client-background">

            <div className='lp-banner-conteudo '>
                <div className='lp-banner-conteudo-left-container'>
                    <div className='lp-banner-conteudo-left-text-container'>
                        <h1 className='lp-banner-conteudo-left-title' >{datas.title}</h1>
                        <p className='lp-banner-conteudo-left-subtitle'>{datas.subtitle}</p>
                    </div>
                    <div className={datas.leftButtonClass} >
                        
                        {
                            datas.right === "img" ?

                            <a href={datas.btnLink} target={'_blank'} rel="noreferrer" className='btn-lp'>{datas.btn1}</a>
                            :
                            <></>

                        }
                        <a href={datas.btn2Link} target={'_blank'} rel="noreferrer" className='btn-lp-2'>{datas.btn2}</a>
                    </div>
                </div>
                <div className={datas.rightClass} >
                {
                    datas.right === "img" ? 
                    
                    <img className='lp-banner-conteudo-right-image' src={datas.img}/> 
                    :
                    <div style={{width: "80%", marginBottom: 15}} className={datas.formClass}></div>
                }
                
                    
                </div>
            </div>
        
        </div>
    );
}

export default LandingPageBanner;