import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="clearfix" style={{backgroundColor: 'rgb(27, 5, 83)'}}>
                <div id="footer-widgets" className="container">
                    <div className="themesflat-row gutter-30">
                        <div className="col span_1_of_3">
                            <div className="widget widget_text">
                                <div className="textwidget">
                                    <p>
                                        <img src="assets/img/orni_logo_orni_hlogo_white.webp" alt="logotipo empresa ORNI" width={170} height={34} />
                                    </p>
                                    <p className="margin-bottom-15">
                                        <img src="assets/img/tecnologiaquegeralucro.webp" alt="slogam escrito tecnologia que gera luro" width={170} height={34} />
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-map-marker" />
                                                <span className="text" style={{fontFamily: "Sora"}}>Tv. São José, 455 - Navegantes<br/> Porto Alegre - RS - <a href='https://www.google.com/maps/place/Orni+-+Desenvolvimento+de+Software/@-29.9964175,-51.2034852,17z/data=!3m1!4b1!4m5!3m4!1s0x951979af3559300b:0x3b3cd2241b359604!8m2!3d-29.9964175!4d-51.2012965' target={'_blank'} rel="noreferrer"> (Veja no mapa) </a></span>
                                            </div>
                                        </li>
                                        
                                        <li className="margin-top-7">
                                            <div className="inner">
                                                <span className=" font-size-14 fa fa-envelope" />
                                                <span className="text"><a href='mailto:contato@orni.com.br' target={'_blank'} rel="noreferrer">contato@orni.com.br</a></span>
                                            </div>
                                        </li>

                                        <li className="margin-top-9">
                                            <div className="inner">
                                                <span className=" footer-icons-color font-size-14 fa fa-whatsapp" />
                                                <span className="text"><a href='https://api.whatsapp.com/send?phone=5551989065423&text=' target={'_blank'} rel="noreferrer">+55 51 98906-5423</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>{/* /.widget_text */}
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                            <div className="widget widget_lastest">
                                <h2 className="widget-title"><span>LINKS PRINCIPAIS</span></h2>
                                <ul className="lastest-posts data-effect clearfix">
                                    <li className="clearfix">
                                        <div className="text">
                                            <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>QUEM SOMOS</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                            <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>O QUE FAZEMOS</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                            <Link to="/contato" onClick={() => {window.location.href="/contato"}}>ENTRAR EM CONTATO</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                        <Link to="/contato" onClick={() => {window.location.href="/contato"}}>AGENDAR UMA VISITA</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>{/* /.widget_lastest */}
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                            <div className="widget widget_lastest">
                                <h2 className="widget-title"><span>SOLUÇÕES</span></h2>
                                <ul className="lastest-posts data-effect clearfix">
                                    <li className="clearfix">
                                        <div className="text">
                                        <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>ORNI START</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                        <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>DESENVOLVIMENTO</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                        <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>INFRAESTRUTURA</Link>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="text">
                                        <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}}>VR E AR</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>{/* /.widget_lastest */}
                        </div>{/* /.col */}
                        <div className="col span_1_of_3">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={0} />
                            <div className="widget widget_lastest">
                                <h2 className="widget-title"><span>SIGA A ORNI</span></h2>
                                <ul className="lastest-posts data-effect clearfix">
                                    <li className="clearfix">
                                        <div className="footer-logos text">
                                            <a href='https://www.facebook.com/ornibr/' target={'_blank'} rel="noreferrer" ><img alt='ícone facebook' src='assets/img/footer/facebook.webp'/></a>
                                            <a href='https://www.instagram.com/orni.com.br/' target={'_blank'} rel="noreferrer"><img alt='ícone instagram' src='assets/img/footer/instagram.webp'/></a>
                                            <a href='https://www.linkedin.com/company/ornibr/' target={'_blank'} rel="noreferrer"><img alt='ícone linkedin' src='assets/img/footer/linkedin.webp'/></a>
                                        </div>
                                    </li>
                                    <li className="clearfix">
                                        <div className="footer-certifications">
                                            <a id='footer-cald-logo' className='footer-certification-logo' href='https://www.meta.com/' target={'_blank'} rel="noreferrer"><img alt='ícone meta' src='assets/img/footer/meta.webp'/></a>
                                            <a id='footer-aws-logo' className='footer-certification-logo' href='https://aws.amazon.com/' target={'_blank'} rel="noreferrer"><img alt='ícone amazon aws' src='assets/img/footer/aws.webp'/></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>{/* /.widget_lastest */}
                        </div>{/* /.col */}
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;