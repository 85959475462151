import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Slider, Partner, OrniLabs, Caldeira } from '../layouts/home01/index'
import {Header, Footer, TopBar, BottomBar, LandingPageBanner} from '../layouts/general/index'
import { HighTech, Team, Time, VentureBuilder } from '../layouts/createApp/index'
import menuApp from '../layouts/menuApp'
import bannerApp from '../layouts/createApp/data_banner';
import { Helmet } from 'react-helmet';
import helmet from '../data/helmet';
import FooterNew from '../layouts/general/Footer-2'
import HeaderNew from '../layouts/general/Header-2'


class CreateApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Home",
                }
            ],
            headingchoose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'ECOSSISTEMA',
                    text: 'More than 500 projects completed in Autora - With over 25 years of combined experience, we have the knowledge to serve you.',
                    classtext: 'sub-heading',
                }
            ],
        }
    }
    
    render() {
        console.log(this.props.location.pathname);

        var title;

        
        

        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search" style={{backgroundColor: '#4c9fc8'}}>

                { 

                    helmet.map(data => (
                        this.props.location.pathname === data.url ? 
                        <Helmet>
                            <title>{data.title}</title>
                            <meta name="description" content="Somos specialistas em criar aplicativos, somamos mais de 110 anos de experiência trabalhando com tecnologia de ponta e negócios, prontos para resolver todos os seus problemas imediatamente."/>

                            <meta name="keywords" content="Desenvolvimento de software, Desenvolvimento de aplicativos, Empresa de desenvolvimento de software, Desenvolvimento web, Soluções de software, Desenvolvimento ágil, Consultoria em desenvolvimento de software, Engenharia de software, Automação de processos, Integração de sistemas, Testes de software, Manutenção de software, Arquitetura de software, Tecnologia da informação"/>

                            <meta name="author" content="Orni.com.br"/>

                            <meta name="robots" content="index, follow"/>

                            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

                            <link rel="canonical" href="https://www.orni.com.br/"/>

                            <meta property="og:title" content={data.title}/>

                            <meta property="og:description" content="Nossa equipe de especialistas em tecnologia tem somados mais de 110 anos de experiência trabalhando com tecnologia de ponta e negócios, prontos para resolver todos os seus problemas imediatamente."/>

                            <meta property="og:image" content="https://orni.com.br/assets/img/og.png"/>

                            <meta property="og:type" content="company"/>
            
                            <meta property="og:url" content={data.path}/>

                            <meta property="og:site_name" content={data.title}/>

                            <meta name="twitter:card" content="summary"/>
                            
                            <meta name="twitter:url" content={data.path}/>
                            
                            <meta name="twitter:image" content="https://orni.com.br/assets/img/og.png"/>
                            
                            <meta name="twitter:title" content={data.title}/>
                            
                            <meta name="twitter:creator" content="orni.com.br"/>
                            
                            <meta name="twitter:description" content="Nossa equipe de especialistas em tecnologia tem somados mais de 110 anos de experiência trabalhando com tecnologia de ponta e negócios, prontos para resolver todos os seus problemas imediatamente."/>

                        </Helmet> 
                        :
                        null
                    ))

                }
                

                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            {/* <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={menuApp} key={idx}/>
                                ))
                            } */}

                            {
                                helmet.map(data => (
                                    data.url === this.props.location.pathname ?
                                    <HeaderNew data={data.header}/>
                                    :
                                    null
                                ))
                            }

                        </div>
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            
                                            <LandingPageBanner data={bannerApp} />
                                            
                                            <HighTech />
                                            <Time />

                                            {/* <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} /> */}
                                            
                                            <VentureBuilder/>


                                            <Caldeira/>

                                            {/* <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} /> */}

                                            <Team/>
                                            
                                            {/* <div className="row-tabs">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <Conteudo/>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <Partner/>

                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="_form_22"></div>
                        <script src="https://orni.activehosted.com/f/embed.php?id=22" type="text/javascript" charset="utf-8"></script>
                     */}   <FooterNew /> 
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateApp;