import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import bannerhome1 from "./data_banner";

class Slider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location } = this.props;
    const mainContent = () => {
      return (
        <>
          {bannerhome1.map((data) => (
            <li
              data-transition="random"
              key={data.id}
            >
              <div
                className="desktop-image"
                style={{
                  opacity: 0.9,
                  "--image-src-desktop": `url(${data.originalSrc})`,
                  "--image-src-mobile": `url(${
                    data.mobileSrc === null ? data.originalSrc : data.mobileSrc
                  })`
                }}
                alt="altimage"
                data-bgposition="center center"
                data-no-retina
              />
              <div
                className={data.classnames}
                data-x={data.datax}
                data-hoffset={data.datahset}
                data-y="['middle','middle','middle','middle']"
                data-voffset={data.datavset1}
                data-fontsize="['20','17','15','10']"
                data-lineheight="['70','70','40','24']"
                data-width="full"
                data-height="none"
                data-whitespace="normal"
                data-transform_idle="o:1;"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                data-mask_in="x:0px;y:[100%];"
                data-mask_out="x:inherit;y:inherit;"
                data-start={700}
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                {data.text1}
              </div>
              <div
                className={data.classtext}
                data-x={data.datax}
                data-hoffset={data.datahset}
                data-y="['middle','middle','middle','middle']"
                data-voffset={data.datavset2}
                data-fontsize="['42','38','32','22']"
                data-lineheight="['85','85','45','35']"
                data-width="full"
                data-height="none"
                data-whitespace="normal"
                data-transform_idle="o:1;"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                data-mask_in="x:0px;y:[100%];"
                data-mask_out="x:inherit;y:inherit;"
                data-start={1000}
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                {data.text2}
              </div>
              <div
                className={data.classtext}
                data-x={data.datax}
                data-hoffset={data.datahset}
                data-y="['middle','middle','middle','middle']"
                data-voffset={data.datavset3}
                data-fontsize="['52','42','32','22']"
                data-lineheight="['85','85','45','35']"
                data-width="full"
                data-height="none"
                data-whitespace="normal"
                data-transform_idle="o:1;"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                data-mask_in="x:0px;y:[100%];"
                data-mask_out="x:inherit;y:inherit;"
                data-start={1000}
                data-splitin="none"
                data-splitout="none"
                data-responsive_offset="on"
              >
                {data.text3}
              </div>
              {data.id === 1 && (
                <div
                  className={data.classbtn}
                  data-x={data.datax}
                  data-hoffset={data.datahset}
                  data-y="['middle','middle','middle','middle']"
                  data-voffset={data.datavset}
                  data-width="full"
                  data-height="none"
                  data-whitespace="normal"
                  data-transform_idle="o:1;"
                  data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                  data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                  data-mask_in="x:0px;y:[100%];"
                  data-mask_out="x:inherit;y:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                >
                  <a
                    href={data.link}
                    className="default-button"
                  >
                    <span>SAIBA MAIS</span>
                  </a>
                </div>
              )}

              {data.id === 2 && (
                <div
                  className={data.classbtn}
                  data-x={data.datax}
                  data-hoffset={data.datahset}
                  data-y="['middle','middle','middle','middle']"
                  data-voffset={data.datavset}
                  data-width="full"
                  data-height="none"
                  data-whitespace="normal"
                  data-transform_idle="o:1;"
                  data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                  data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                  data-mask_in="x:0px;y:[100%];"
                  data-mask_out="x:inherit;y:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                >
                  <a
                    href={data.link}
                    className="default-button"
                  >
                    <span>SAIBA MAIS</span>
                  </a>
                </div>
              )}
            </li>
          ))}
        </>
      );
    };
    return <ul>{mainContent()}</ul>;
  }
}

export default withRouter(Slider);
