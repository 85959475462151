import React, { Component } from "react";
import { Link } from "react-router-dom";

import footerLinks from "../../data/footerLinks";

const FooterNew = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content-container">
          <div className="footer-info-container">
            <Link
              style={{ width: 200 }}
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                className="footer-info-logo"
                src="/assets/img/orni-white.png"
                alt="LOGO ORNI"
                width={200}
                height={30}
                data-retina="assets/img/orni-white.png"
              />
            </Link>
            <p className="footer-info-text">
              Somos uma fábrica de startup que conecta empreendedores à
              investidores, usando alta tecnologia.
            </p>
            <div className="footer-social-container">
              <a
                href="https://api.whatsapp.com/send?phone=5551989065423&text="
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt="ícone facebook"
                  src="assets/img/whatsapp.png"
                  width={30}
                  height={30}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ornibr/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt="ícone linkedin"
                  src="assets/img/linked_in.png"
                  width={28}
                  height={30}
                />
              </a>
              <a
                href="https://www.youtube.com/@OrniGlobal"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt="ícone youtube"
                  src="assets/img/youtube.png"
                  width={38}
                  height={38}
                />
              </a>
              <a
                href="https://www.instagram.com/orni.com.br/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  alt="ícone instagram"
                  src="assets/img/instagram.png"
                  width={28}
                  height={30}
                />
              </a>
            </div>
          </div>
          <div className="footer-responsive">
            <div className="footer-links-container">
              <h3 className="footer-link-title">Links:</h3>
              {footerLinks.map((item) => (
                <div className="footer-link-item-container">
                  {item.id === 0 ||
                  item.id === 1 ||
                  item.id === 2 ||
                  item.id === 4 ? (
                    <Link
                      to={item.link}
                      onClick={() => {
                        window.location.href = item.link;
                      }}
                      className="footer-link-item"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <a
                      href={item.link}
                      target={"_blank"}
                      rel="noreferrer"
                      className="footer-link-item"
                    >
                      {item.title}
                    </a>
                  )}
                </div>
              ))}
            </div>
            <div className="footer-podcast-container">
              <h3 className="footer-link-title">
                Conheça mais a ORNI, ouça o #ORNICAST
              </h3>
              <iframe
                style={{ borderRadius: 12 }}
                src="https://open.spotify.com/embed/episode/0l3Ho692dQi6GAkFsJkDqN?utm_source=generator"
                width="100%"
                height="250"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterNew;
