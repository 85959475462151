import Home01 from './Home01'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import Contact02 from './Contact02'
import ComingSoon from './ComingSoon'
import Terms from './Terms'
import Capte from './Capte'
import Invista from './Invista'
import Labs from './Labs'
import CreateApp from './CreateApp'
import SoftwareHouse from './SoftwareHouse'
import helmet from '../data/helmet'

const routes = [
    { path: '/', component: Home01},
    { path: '/blog', component: Blog},
    { path: '/blog-single', component: BlogSingle},
    { path: '/contato', component: Contact02},
    { path: '/em-construcao', component: ComingSoon},
    { path: '/termos-de-uso', component: Terms },
    { path: '/capte', component: Capte },
    { path: '/invista', component: Invista },
    { path: '/servicos', component: Labs },
    { path: '/criar-aplicativo-porto-alegre', component: CreateApp },
    { path: '/software-house', component: SoftwareHouse },
    { path: '/fabrica-de-software', component: SoftwareHouse },
    { path: '/empresa-de-desenvolvimento-de-software', component: SoftwareHouse },
    { path: '/criar-aplicativo-android', component: CreateApp },
    { path: '/criar-app-android', component: CreateApp },
    { path: '/desenvolvimento-mobile', component: CreateApp },
    { path: '/desenvolvimento-de-aplicativo', component: CreateApp },
    { path: '/criar-aplicativo', component: CreateApp }
]

export default routes;
