import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class BottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [
        {
          id: 1,
          name: "Termos de Uso",
          links: "/termos-de-uso"
        },
        {
          id: 2,
          name: "Segurança da informação",
          links: "/em-construcao"
        }
      ]
    };
  }

  render() {
    return (
      <div id="bottom" className="clearfix has-spacer">
        <div id="bottom-bar-inner" className="container">
          <div className="bottom-bar-inner-wrap">
            <div className="bottom-bar-content">
              <div id="copyright">
                ©{" "}
                <span className="text">
                  Copyright © 2023 Orni Global S/A.
                  
                  CNPJ: 49.747.273/0001-93 - All rights reserved. <br /> Feito
                  com 🧡 por um dev que não toma café 🫠 - Versão 1.1.030
                </span>
              </div>
            </div>
            {/* /.bottom-bar-content */}
            <div className="bottom-bar-menu">
              <ul className="bottom-nav">
                {this.state.menus.map((data) => (
                  <li
                    className={
                      data.name === this.props.data.names
                        ? "menu-item current-menu-item"
                        : "menu-item"
                    }
                    key={data.id}
                  >
                    <Link
                      to={data.links}
                      onClick={() => {
                        window.location.href = data.links;
                      }}
                    >
                      {data.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BottomBar);
