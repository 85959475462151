import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./components/pages/index";

import ReactGA from "react-ga4";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import CookieConsent from "react-cookie-consent";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { withRouter } from "react-router-dom";

import Error404 from "./components/pages/Error404";

function App() {
  const TRACKING_ID = "G-EGNEEZ47G4";

  useEffect(() => {
    const testMode = process.env.NODE_ENV && process.env.NODE_ENV === "test";

    ReactGA.initialize(TRACKING_ID, { testMode: testMode });

    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <CookieConsent
        style={{
          zIndex: 10000,
          backgroundColor: "rgb(8, 14, 27)",
          color: "#fff",
          fontFamily: "Sora",
          //height: '15%',
          //paddingTop: 15
          display: "flex",
          alignItems: "center",
          paddingTop: 21,
          paddingBottom: 20
        }}
        buttonStyle={{
          marginBottom: 20,
          fontSize: 14,
          fontWeight: 600,
          backgroundColor: "#3F01FF",
          color: "#fff",
          fontFamily: "Sora",
          letterSpacing: 0.5,
          borderBottomLeftRadius: 14,
          borderTopRightRadius: 14,
          height: 40
        }}
        buttonText="EU CONCORDO"
      >
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
        experiência em nossos serviços. Ao utilizar nossos serviços, você
        concorda com tal monitoramento.
      </CookieConsent>

      <Fab
        style={{
          position: "fixed",
          height: 75,
          width: 75,
          bottom: "2%",
          right: "2%",
          backgroundColor: "green"
        }}
      >
        <a
          style={{ marginTop: 4.5, marginLeft: 2 }}
          href="https://api.whatsapp.com/send?phone=5551989065423&text="
          target={"_blank"}
          rel="noreferrer"
        >
          <WhatsApp style={{ fontSize: 40, color: "white" }} />
        </a>
      </Fab>

      <Switch>
        {routes.map((data, idx) => (
          <Route
            exact
            path={data.path}
            component={data.component}
            key={idx}
          />
        ))}
        <Route
          path="*"
          component={Error404}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
