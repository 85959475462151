import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class MainC extends Component {
    render() {
        return (
            <div className='coming-body col-md-12'>
                <video autoPlay muted loop className='coming-video'>
                    <source src='assets/img/back.mp4' type='video/mp4' />
                
                
                </video>
                <div className='coming-text'>
                    {/* <h1>PARABÉNS!</h1> */}
                    <h3 style={{fontFamily:'Michroma'}}>ESTAMOS TRABALHANDO <br/> PARA CONSTRUIR ESTA PÁGINA PARA VOCÊ</h3>  

                    <div className='coming-buttons-container'>
                        <div className='coming-buttons-container'>
                            <div className="text-center">
                                <Link to="/" onClick={() => {window.location.href="/"}} className="default-button">VOLTE AO INÍCIO</Link>
                            </div>
                            <div className="text-center">
                            <a href='https://api.whatsapp.com/send?phone=5551989065423&text=Olá!%20Quero%20falar%20sobre%20novos%20negócios' className='default-button' target={'_blank'} rel="noreferrer">ENTRE EM CONTATO</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
        )
    }
}

export default MainC;