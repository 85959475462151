const menus = [
  {
    id: 1,
    name: "Invista",
    link: "/invista",
    namesub: [],
    // {
    //     id: 1,
    //     sub: 'Quem Somos',
    //     links: '/em-construcao'
    // },
    // {
    //     id: 2,
    //     sub: 'Manifesto',
    //     links: '/em-construcao'
    // },
    // {
    //     id: 3,
    //     sub: 'Time Orni',
    //     links: '/em-construcao'
    // },
    // {
    //     id: 4,
    //     sub: 'Soluções',
    //     links: '/em-construcao'
    // },
    // {
    //     id: 5,
    //     sub: 'Contate-nos',
    //     links: '/em-construcao'
    // }
  },
  {
    id: 2,
    name: "Capte",
    link: "/capte",
    namesub: [
      {
        id: 1,
        sub: "Nosso ecossistema de negócios",
        links: "/em-construcao",
      },
      {
        id: 2,
        sub: "Como fazer parte",
        links: "/em-construcao",
      },
    ],
  },
  // {
  //     id: 3,
  //     name: 'Fábrica de Software',
  //     link: '/em-construcao',
  //     namesub: [

  //         {
  //             id: 1,
  //             sub: 'Orçamento',
  //             links: '/em-construcao'
  //         },
  //         {
  //             id: 2,
  //             sub: 'Produtos',
  //             links: '/em-construcao'
  //         }
  //     ],
  // },
  {
    id: 4,
    name: "Serviços",
    link: "/servicos",
    namesub: [
      {
        id: 1,
        sub: "O que é",
        links: "/em-construcao",
      },
      {
        id: 2,
        sub: "Projetos",
        links: "/em-construcao",
      },
      {
        id: 3,
        sub: "Traga sua ideia",
        links: "/em-construcao",
      },
      {
        id: 4,
        sub: "Investidores",
        links: "/em-construcao",
      },
      {
        id: 5,
        sub: "Parcerias",
        links: "/em-construcao",
      },
    ],
  },
  {
    id: 5,
    name: "Plano de Negócios Orni",
    link: "https://projetos.orni.com.br",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Nosso ecossistema de negócios',
    //         links: '/em-construcao'
    //     },
    //     {
    //         id: 2,
    //         sub: 'Como fazer parte',
    //         links: '/em-construcao'
    //     }
    // ],
  },
  {
    id: 6,
    name: "Comunidade",
    link: "/contato",
    namesub: [],
  },
];

export default menus;
