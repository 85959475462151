const helmet = [
    {
        id: 0,
        title: "Orni - Criar Aplicativo em Porto Alegre-RS",
        url: "/criar-aplicativo-porto-alegre",
        path: "https://orni.com.br/criar-aplicativo-porto-alegre",
        header: "CRIAR APLICATIVO"
    },
    {
        id: 1,
        title: "Orni - Software House",
        url: "/software-house",
        path: "https://orni.com.br/software-house",
        header: "SOFTWARE HOUSE"
    },
    {
        id: 2,
        title: "Orni - Fábrica de Software",
        url: "/fabrica-de-software",
        path: "https://orni.com.br/fabrica-de-software",
        header: "SOFTWARE HOUSE"
    },
    {
        id: 3,
        title: "Orni - Empresa de Desenvolvimento de Software",
        url: "/empresa-de-desenvolvimento-de-software",
        path: "https://orni.com.br/empresa-de-desenvolvimento-de-software",
        header: "SOFTWARE HOUSE"
    },
    {
        id: 4,
        title: "Orni - Criar Aplicativo Android",
        url: "/criar-aplicativo-android",
        path: "https://orni.com.br/criar-aplicativo-android",
        header: "CRIAR APLICATIVO"
    },
    {
        id: 5,
        title: "Orni - Criar App Android",
        url: "/criar-app-android",
        path: "https://orni.com.br/criar-app-android",
        header: "CRIAR APLICATIVO"
    },
    {
        id: 6,
        title: "Orni - Desenvolvimento Mobile",
        url: "/desenvolvimento-mobile",
        path: "https://orni.com.br/desenvolvimento-mobile",
        header: "CRIAR APLICATIVO"
    },
    {
        id: 7,
        title: "Orni - Desenvolvimento de Aplicativo",
        url: "/desenvolvimento-de-aplicativo",
        path: "https://orni.com.br/desenvolvimento-de-aplicativo",
        header: "CRIAR APLICATIVO"
    },
    {
        id: 8,
        title: "Orni - Criar Aplicativo",
        url: "/criar-aplicativo",
        path: "https://orni.com.br/criar-aplicativo",
        header: "CRIAR APLICATIVO"
    },
]

export default helmet;
