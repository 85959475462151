const dataAreas = [
    {
        img: "assets/img/orniLabs.webp",
        img_alt: "Eu eiusmod sunt ex aliqua.",
        title: "ORNI LABS",
        subtitle: "Projetos criados e desenvolvidos exclusivamente dentro da Orni, para atender as demandas mais requisitadas do mercado."
        
    },
    {
        img: "assets/img/orniEcossistema.webp",
        img_alt: "Eu eiusmod sunt ex aliqua.",
        title: "ECOSSISTEMA",
        subtitle: "Somos parte de um grande ecossistema de parceiros que auxiliam entre si seus produtos e serviços para que todos se beneficiem e cresçam em conjunto."
    },
    {
        img: "assets/img/orniTech.webp",
        img_alt: "Eu eiusmod sunt ex aliqua.",
        title: "ORNI TECH",
        subtitle: "Tecnologia presente em todo o processo de novos produtos e serviços."
     },
]

export default dataAreas;