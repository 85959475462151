import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import menus from '../menu'
import Modal from '@mui/material/Modal';
import { useState } from 'react';

const Header = (props) => {

    const app = props.data
    console.log(app);

    const [open, setOpen] = React.useState(false);
    //const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false); 

    return (
        <header id="site-header" className='is-fixed'>
            <div id="site-header-inner" className="container">                    
                <div className="wrap-inner clearfix">
                    <div id="site-logo" className="clearfix">
                        <div id="site-log-inner">
                            <Link to="/" onClick={() => {window.location.href="/"}} rel="home" className="main-logo">
                                <img src='/assets/img/orni-white.png' alt="LOGO ORNI" width={130} height={30} data-retina="assets/img/orni-white.png" data-width={150} data-height={30} />
                            </Link>
                        </div>
                    </div>
                    <div className="mobile-button">
                        <span />
                    </div>
                    <nav id="main-nav" className="main-nav">
                        <ul id="menu-primary-menu" className="menu">
                            {
                                app.map(data => (

                                    <li className={data.name === props.data.names ? "menu-item menu-item-has-children current-menu-item" : "menu-item menu-item-has-children"} >
                                    
                                        {/* <Link to={data.link}  >{data.name}</Link> */}
                                        

                                        {
                                            data.id === 5 ? 
                                            <a href={data.link} target={'_blank'} rel="noreferrer">{data.name}</a>
                                            :
                                            <Link to={data.link} onClick={() => {window.location.href=data.link} }>{data.name}</Link>
                                        }

                                        
                                        {/*                                        
                                        
                                        

                                    
                                        data.namesub.length === 0  
                                            ?
                                            <></>
                                            :
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map(submenu => (

                                                        <li className={location.pathname === submenu.links ? "menu-item current-item" : "menu-item"}  key={submenu.id} ><Link to={submenu.links}  onClick={() => {window.location.href=submenu.links}}>{submenu.sub}</Link></li>
                                                    ))
                                                }
                                                
                                            </ul>  

                                        }  */}
                                    </li>

                                ))
                                
                            }
                            
                        </ul>
                        
                    </nav>
                </div>   

                <Modal
                    open = {open}
                    onClose = {handleClose}
                >
                    <div className='header-modal'>
                        EXEMPLO DO MODAL
                    </div>
                </Modal>

            </div>
        </header>
    );

}

export default withRouter(Header);
