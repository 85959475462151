import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Slider, TitleHeading, IconBox, About, Service, Project, ServicesBox, Team, Conteudo, Overview, Faq, Partner, Testimonial, OrniLabs, Caldeira} from '../layouts/home01/index'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import CookieConsent from 'react-cookie-consent'
import NewsLetter from '../layouts/home01/NewsLetter'
import menus from '../layouts/menu'
import ModalComponent from '../layouts/general/ModalComponent'
import FooterNew from '../layouts/general/Footer-2'

class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Home",
                }
            ],
            headingchoose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'ECOSSISTEMA',
                    text: 'More than 500 projects completed in Autora - With over 25 years of combined experience, we have the knowledge to serve you.',
                    classtext: 'sub-heading',
                }
            ],
            modalState: true
        }
        this.handleShow = this.handleShow.bind(this);
    }

    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }
    
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">

                {/* <div>
                <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" style={{width: 600, height: 600 }} >
                        <div className="modal-content">
                            
                            <div className="modal-body">
                                <img className='modal-image' src="assets/img/proj.png" alt="novidade" />
                                <h4 style={{marginTop: 30, color: 'black'}}>CONHEÇA O PROJETO TÉCNICO DA ORNI</h4>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary"><a href="https://projetos.orni.com.br" target={'_blank'} rel="noreferrer">VISITAR A PÁGINA</a></button>
                                <button type="button" className="btn btn-secondary" onClick={this.handleShow} >FECHAR</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div> */}

                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={menus} key={idx}/>
                                ))
                            }
                        </div>
                        {/* <ModalComponent/> */}
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            
                                            <div className="rev_slider_wrapper fullwidthbanner-container">
                                                <div id="rev-slider1" className="rev_slider fullwidthabanner">
                                                    <Slider/>
                                                </div> 
                                            </div>
                                            <div className="row-iconbox">
                                                <div className="container">
                                                    {/* <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                                                            {
                                                                this.state.headingchoose.map(data => (
                                                                    <TitleHeading data={data} key={data.id}/>
                                                                ))
                                                            }
                                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                                        </div>
                                                    </div>
                                                     */}
                                                     {/* <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} /> */}
                                                     
                                                    <IconBox />
                                                    
                                                </div>
                                            </div>
                                            
                                            <About />

                                            {/* <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} /> */}
                                            
                                            <Caldeira/>

                                            <div className="themesflat-spacer clearfix" data-desktop={40} data-mobile={40} data-smobile={40} />

                                            <OrniLabs/>



                                            <Team/>
                                            
                                            <div className="row-tabs">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <Conteudo/>
                                                    </div>
                                                </div>
                                            </div>
                                            <Partner/>

                                            {/* <NewsLetter/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="_form_22"></div>
                        <script src="https://orni.activehosted.com/f/embed.php?id=22" type="text/javascript" charset="utf-8"></script>
                     */}  
                      {/* <Footer />  */}
                        <FooterNew/>
                        
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Home01;