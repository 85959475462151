import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Team extends Component {
    render() {
        return(
            <div className='bg-image team-background '>
                <div className='team-panel' >
                
                <div className="themesflat-spacer clearfix" data-desktop={103} data-mobile={50} data-smobile={50} />
                    <div className='heading' >
                        <h1 className='heading'>ORNI TEAM</h1>
                    </div>
                    <div className='team-text'>
                        <h6>Esses são nossos guerreiros digitais, armados com os equipamentos do mais alto nível para acabar com qualquer obstáculo no caminho do sucesso. Qualquer missão dada, é missão cumprida! E o melhor de tudo é que você é quem fica com os espólios! Vem ver os stats dos nossos desafiantes e faça parte do squad.</h6>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={83} data-mobile={40} data-smobile={40} />
                    <div  className='team-button-container'>
                        {/* <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}} className="default-button">QUEM SOMOS</Link> */}
                        <Link to="/contato" onClick={() => {window.location.href="/contato"}} className="default-button">FAÇA PARTE</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;