import React from 'react';
import  useScript  from '../../hooks/useScript';


const FormContact = (props) => {
    useScript('https://orni.activehosted.com/f/embed.php?id=14');

    const data = props.data;
    
        return (
            <div className='contact-body col-md-12'>

                <video autoPlay muted loop className='contact-video'>
                    <source src='assets/img/contact.mp4' type='video/mp4' /> 
                </video>

                <h1 style={{top: "5%"}} className='contact-text'>{data.title} </h1>

                <p style={{top: "12%"}} className='contact-subtext'>{data.subtitle}</p>

                <a style={{backgroundColor: "green", top: "17%", position: "absolute" }} href='https://api.whatsapp.com/send?phone=5551989065423&text=Olá!%20Quero%20falar%20sobre%20novos%20negócios' className='default-button down-a-litlle' target={'_blank'} rel="noreferrer">ENTRAR NO GRUPO</a>
                          
                <h1 className='contact-text2'>{data.title2}</h1>

                <p className='contact-subtext2'>{data.subtitle2}</p>

                <div className="_form_14" style={{position: 'absolute'}}></div>

            </div>
        );
    
}

export default FormContact;
