import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,
                    classnames: 'project-item green villa',
                    srcimg: 'assets/img/ourProjects/panam.png',
                    heading: 'Panam vêm ao mercado com uma solução inovadora para aquisição e venda de crédito de carbono verde em um marketplace acessível e simples.',
                    name01: 'Green House',
                    name02: 'Villa'
                },
                {
                    id: 2,
                    classnames: 'project-item architecture building construction',
                    srcimg: 'assets/img/ourProjects/nursing.png',
                    heading: 'Easy Nursing propõe a criação de um guia digital de processos e boas práticas da saúde, focado para os profissionais da área, como médicos e enfermeiros.',
                    name01: 'Architecture',
                    name02: ''
                },
                {
                    id: 3,
                    classnames: 'project-item villa architecture building construction',
                    srcimg: 'assets/img/ourProjects/agenciu.png',
                    heading: 'Plataforma para você ser um agenciador de venda e compra de carros. Conte com especialistas que gerenciam com agilidade o melhor negócio para você!',
                    name01: 'Green House',
                    name02: 'Villa'
                },
                {
                    id: 4,
                    classnames: 'project-item green villa',
                    srcimg: 'assets/img/ourProjects/beefree.png',
                    heading: 'Não importa a cidade em que você está, experimente com a Bee Free novas soluções com os mais diversos tipos de serviços e ainda conte com um preço acessível.',
                    name01: 'Green House',
                    name02: 'Villa'
                },
                {
                    id: 5,
                    classnames: 'project-item architecture building construction',
                    srcimg: 'assets/img/ourProjects/hality.png',
                    heading: 'Revolucione o diagnóstico e tratamento da halitose com um aplicativo que mapeia e identifica traços indicadores do mau hálito, auxiliando-o a solucioná-lo.',
                    name01: 'Architecture',
                    name02: ''
                },
                {
                    id: 6,
                    classnames: 'project-item green',
                    srcimg: 'assets/img/ourProjects/sindilojas.png',
                    heading: 'O Sindicato dos Lojistas do Comércio de Porto Alegre é o representante legal do comércio varejista e defende  os interesses de mais de 20 mil empresas de diversos segmentos.',
                    name01: 'Green House',
                    name02: 'Villa'
                }
            ]
        }
    }

    render() {
        return (
            <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
                {
                this.state.projects.map(data =>(
                <div className={data.classnames} key={data.id}>
                    <div className="inner">
                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                            <img src={data.srcimg} alt="altimage" />
                            {/* <div className="elm-link">
                                <Link to="#" className="icon-1 icon-search" />
                                    <Link to="#" className="icon-1" />
                            </div>
                            <div className="overlay-effect bg-color-1" /> */}
                        </div>
                        <div className="text-wrap" style={{backgroundColor: "#180156"}}>
                            <h5 className="heading" style={{textAlign: 'justify'}} >
                                {data.heading}
                            </h5>   
                        </div>
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default (TabProject);