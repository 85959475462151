import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LeaderTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: [
                {
                    id: 1,
                    srcimg: 'assets/img/team/member-2-370x359.jpg',
                    name: 'LAB. DE INOVAÇÃO',
                    position: 'CONHEÇA'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/team/member-2-370x359.jpg',
                    name: 'FAMINTOS',
                    position: 'CONHEÇA'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/team/member-3-370x359.jpg',
                    name: 'CARBONO',
                    position: 'CONHEÇA'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/team/member-1-370x359.jpg',
                    name: 'ORNI BANK',
                    position: 'CONHEÇA'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/team/member-2-370x359.jpg',
                    name: 'ORNI LABS X',
                    position: 'CONHEÇA'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/team/member-3-370x359.jpg',
                    name: 'ORNI LABS X',
                    position: 'CONHEÇA'
                },
                {
                    id: 7,
                    srcimg: 'assets/img/team/member-1-370x359.jpg',
                    name: 'ORNI LABS X',
                    position: 'CONHEÇA'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/team/member-2-370x359.jpg',
                    name: 'ORNI LABS X',
                    position: 'CONHEÇA'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="row-about client-background" style={{backgroundColor: '#090e1c'}}>
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                        {/* <div className="col-md-5 half-background style-1"/> */}
                        <div className="col-md-6 ">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h1 className='heading'>ORNI LABS </h1>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> 
                                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />                                              
                                    <h4>Nosso laboratório de inovações tecnológicas é voltado ao desenvolvimento de projetos inovadores que agregam valor ao ecossistema ORNI.<br/> <br/> Aqui transformamos ideias em projetos de negócios rentáveis integrados ao nosso ecossistema. <br/> <br/><strong><i>TRAGA SUA IDEIA OU INVISTA EM UM DE NOSSOS PROJETOS!</i></strong></h4>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                    {/* 
                                        Versão 161 apenas
                                    
                                    <div className='eco-buttons-container'>
                                        <div className="">
                                                <Link to="/em-construcao" onClick={() => {window.location.href="/em-construcao"}} className="default-button">CONHEÇA O LABS</Link>
                                        </div>
                                    </div> */}
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                        <div className="col-md-6 half-background style-2" />
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaderTeam;