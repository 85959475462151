import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopBar extends Component {
    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap" style={{marginRight: 8}}>
                        <div className="top-bar-socials">
                            <div className="inner">
                               
                                <span className="icons">

                                    <a href='https://www.linkedin.com/company/ornibr/' target={'_blank'} rel="noreferrer"><i className='fa fa-linkedin'/></a>
                                    <a href='https://www.youtube.com/channel/UCx8F-rRdQOTCCqsoyjsfNDw' target={'_blank'} rel="noreferrer"><i className='fa fa-youtube'/></a>
                                    <a href='https://www.instagram.com/orni.com.br/' target={'_blank'} rel="noreferrer"><i className='fa fa-instagram'/></a>   
                                    
                                    
                                </span>
                                
                                {/* <span className="text text-white" > <a href='https://orni.ac-page.com/retrospectiva-2022' target={"_blank"} rel="noreferrer">Restrospectiva 2022</a> </span> */}
                                <span className="text text-white" style={{marginRigth: -50}}> <a href='https://ofertas.orni.com.br' target={'_blank'} rel="noreferrer"> Área do Cliente</a> </span>
                            </div> 
                            
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

export default TopBar;