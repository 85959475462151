import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IconBox extends Component {
    render() {
        return (
            <div id='eco' className='row equalize sm-equalize client-background'>
                <div className="col-md-6 " style={{height: 480, }}>
                    <div className="themesflat-headings style-1 clearfix" style={{marginTop: '30%'}}> {/*Versão 161 apenas*/}
                        <h1 className='heading'> ECOSSISTEMA <br/> DE EXCELÊNCIA <br/> EM TECNOLOGIA</h1>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />                                               
                        <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                        <h4>Potencialize seus resultados com nosso ecossistema de soluções, parceiros e comunidade exclusivos da Orni. Tudo ao seu alcance para transformar ideias em resultados concretos e lucrativos através da tecnologia.</h4>
                        {/* 
                            Alteração temporária na versão 161 enquanto não se definem todas as novas funcionalidades

                        <div className='eco-buttons-container'>
                            <div className="">
                            <Link to="/em-construcao" className='default-button' onClick={() => {window.location.href="/em-construcao"}} >CONHEÇA NOSSO ECOSSISTEMA</Link>
                            </div>
                        </div> */}
                    
                    </div>
                </div>


                <div className="col-md-6 mbl-orbit " style={{height: 480, backgroundColor: '#180156'}}>
                    <div className="orbit ">
            
                        <ul className="orbit-wrap">
                        
                            <li className="orbit-center">
                                <img alt='logotipo orni em azul' src='assets/img/logo-small.webp'/>
                            </li>

                            <li>
                            <ul className="ring-0">
                                <li><i className="orbit-icon fa fa-git"></i></li>
                                <li><i className="orbit-icon fa fa-free-code-camp"></i></li>
                                <li><i className="orbit-icon fa fa-meetup"></i></li>
                                <li><i className="orbit-icon fa fa-codepen"></i></li>
                            </ul>
                            </li>

                            <li>
                            <ul className="ring-1">
                                <li><i className="orbit-icon fa fa-podcast"></i></li>
                                <li><i className="orbit-icon fa fa-css3"></i></li>
                                <li><i className="orbit-icon fa fa-html5"></i></li>
                            </ul>
                            </li>
                            <li>
                            <ul className="ring-2">
                                <li><i className="orbit-icon fa fa-windows"></i></li>
                                <li><i className="orbit-icon fa fa-safari"></i></li>
                                <li><i className="orbit-icon fa fa-edge"></i></li>
                                <li><i className="orbit-icon fa fa-linux"></i></li>
                                <li><i className="orbit-icon fa fa-apple"></i></li>
                                <li><i className="orbit-icon fa fa-chrome"></i></li>
                                <li><i className="orbit-icon fa fa-android"></i></li>
                                <li><i className="orbit-icon fa fa-firefox"></i></li>
                            </ul>
                            </li>
                            <li>
                            <ul className="ring-3">
                                <li><i className="orbit-icon fa fa-coffee"></i></li>
                                <li><i className="orbit-icon fa fa-terminal"></i></li>
                                <li><i className="orbit-icon fa fa-heart-o"></i></li>
                            </ul>
                            </li>

                        </ul>
                    
                    </div>
                </div>
                


            </div>

            
        );
    }
}

export default IconBox;